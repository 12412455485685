import { Input as TamaguiInput, styled, XStack } from 'tamagui'

export const InputContainer = styled(XStack, {
  name: 'InputContainer',
  backgroundColor: '$buttonBg',
  borderColor: '$hairline',
  alignItems: 'center',
  borderWidth: 1,
  gap: '$2',
  borderRadius: '$1',
  cursor: 'text',
  hoverStyle: {
    borderColor: '$primaryText',
  },
  variants: {
    isFocused: {
      true: {
        borderColor: '$primaryText',
      },
    },
    success: {
      true: {
        borderColor: '$greenText',
        hoverStyle: {
          borderColor: '$greenText',
        },
      },
    },
    failure: {
      true: {
        borderColor: '$redPrimary',
        hoverStyle: {
          borderColor: '$redPrimary',
        },
      },
    },
    warning: {
      true: {
        borderColor: '$amberPrimary',
        hoverStyle: {
          borderColor: '$amberPrimary',
        },
      },
    },
    isDisabled: {
      true: {
        borderColor: '$hairline',
        backgroundColor: '$disabledBg',
        cursor: 'default',
        color: '$secondaryText',
        hoverStyle: {
          borderColor: '$hairline',
        },
      },
    },
  } as const,
})

export const Input = styled(TamaguiInput, {
  name: 'Input',
  unstyled: true,
  color: '$primaryText',
  placeholderTextColor: '$secondaryText',
  fontFamily: '$special',
  flexGrow: 1,
  minWidth: 0,
  variants: {
    isDisabled: {
      true: {
        color: '$primaryText',
        placeholderTextColor: '$primaryText',
      },
    },
    isPlaceholder: {
      true: {
        color: '$secondaryText',
      },
    },
    size: {
      sm: {
        height: '$sm',
        fontSize: '$sm',
        letterSpacing: '$sm',
      },
      md: {
        height: '$md',
        fontSize: '$md',
        letterSpacing: '$md',
      },
      lg: {
        height: '$lg',
        fontSize: '$lg',
        letterSpacing: '$lg',
      },
    },
  } as const,
})
