'use client'

import { ProtocolFamily, RawPrivateKey } from '@coinbase/waas-sdk-web'
import { useWalletContext } from '@coinbase/waas-sdk-web-react'
import useAuth from '@lyra/web/hooks/useAuth'
import { fetchBackupClient } from '@lyra/web/utils/client/waas'
import emptyFunction from '@lyra/web/utils/emptyFunction'
import React, { createContext, useCallback, useMemo } from 'react'

type WaasContext = {
  isReady: boolean
  exportPrivateKeySENSITIVE: (passcode: string) => Promise<RawPrivateKey>
}

export const WaasContext = createContext<WaasContext>({
  isReady: false,
  exportPrivateKeySENSITIVE: emptyFunction as any,
})

export default function WaasInternalProvider({ children }: { children: React.ReactNode }) {
  const { privy, isAuthenticated } = useAuth()
  const { waas, wallet } = useWalletContext()

  // wait for waas and privy auth
  const isReady = !!waas && privy.isReady

  /**
   * Connect to MPC wallet
   */
  const restoreWallet = useCallback(
    async (passcode: string) => {
      if (!isAuthenticated) {
        throw new Error('Not authenticated')
      }
      if (!waas) {
        throw new Error('WaaS not ready')
      }
      const backup = await fetchBackupClient()
      await waas.logout()
      try {
        const restoredWallet = await waas.restoreFromBackup(backup.backup, passcode)
        await restoredWallet.addresses.for(ProtocolFamily.EVM)
        return restoredWallet
      } catch (e) {
        await waas.logout()
        throw e
      }
    },
    [isAuthenticated, waas]
  )

  const exportPrivateKeySENSITIVE = useCallback(
    async (passcode: string) => {
      if (!isAuthenticated) {
        throw new Error('Not authenticated')
      }
      if (!isReady) {
        throw new Error('WaaS not ready')
      }
      let _wallet = wallet
      if (!_wallet) {
        _wallet = await restoreWallet(passcode)
      }
      const backup = await fetchBackupClient()
      const keys = await _wallet.exportKeys(backup.backup, passcode)
      return keys[0] as RawPrivateKey
    },
    [isAuthenticated, isReady, restoreWallet, wallet]
  )

  const value = useMemo(
    () => ({
      isReady,
      exportPrivateKeySENSITIVE,
    }),
    [isReady, exportPrivateKeySENSITIVE]
  )

  return <WaasContext.Provider value={value}>{children}</WaasContext.Provider>
}
