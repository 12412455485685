import { HistoryTab } from '../constants/history'
import { MarketId } from './markets'
import { CollateralId } from './tokens'

export enum PageId {
  CreateWallet = 'CreateWallet',
  Landing = 'Landing',
  TermsOfUse = 'TermsOfUse',
  PrivacyPolicy = 'PrivacyPolicy',
  Options = 'Options',
  Perps = 'Perps',
  Home = 'Home',
  History = 'History',
  RewardsHistory = 'RewardsHistory',
  Settings = 'Settings',
  Balances = 'Balances',
  Developers = 'Developers',
  Alpha = 'alpha',
  Stats = 'Stats',
  Airdrop = 'Airdrop',
  PointsLeaderboard = 'PointsLeaderboard',
  TradersLeaderboard = 'TradersLeaderboard',
  Yield = 'Yield',
  Migration = 'Migration',
  Rounds = 'Rounds',
  YieldToken = 'YieldToken',
  Invites = 'Invites',
  Lend = 'Lend',
  LendCollateral = 'LendCollateral',
  Rfq = 'Rfq',
  Partners = 'Partners',
  Positions = 'Positions',
  Amberdata = 'Amberdata',
}

export type PageArgsMap = {
  [PageId.Landing]: undefined
  [PageId.TermsOfUse]: undefined
  [PageId.PrivacyPolicy]: undefined
  [PageId.Options]:
    | {
        marketId?: MarketId
        instrumentName?: string
        isBuy?: boolean
        size?: number
        limitPrice?: number
      }
    | undefined
  [PageId.Perps]:
    | {
        marketId?: MarketId
        size?: number
        limitPrice?: number
      }
    | undefined
  [PageId.Home]: undefined
  [PageId.History]: {
    tab?: HistoryTab
  }
  [PageId.RewardsHistory]: undefined
  [PageId.Settings]: undefined
  [PageId.CreateWallet]: undefined
  [PageId.Alpha]: undefined
  [PageId.Developers]: undefined
  [PageId.Stats]: undefined
  [PageId.Airdrop]: {
    code?: string
  }
  [PageId.PointsLeaderboard]: undefined
  [PageId.Balances]: undefined
  [PageId.Yield]: undefined
  [PageId.YieldToken]: {
    id: string
  }
  [PageId.Migration]: undefined
  [PageId.Rounds]: undefined
  [PageId.Invites]: undefined
  [PageId.Lend]: undefined
  [PageId.LendCollateral]: {
    collateral: CollateralId
  }
  [PageId.Rfq]: undefined
  [PageId.Partners]: undefined
  [PageId.Positions]: undefined
  [PageId.Amberdata]: undefined
  [PageId.TradersLeaderboard]: undefined
}

export type Pathname = `/${string}`

type PageArgsWithPageMap<M extends Record<string, any>> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        page: Key
      }
    : {
        page: Key
      } & M[Key]
}
export type PagePathArgs<T extends PageId> = PageArgsWithPageMap<PageArgsMap>[T]

export const CREATE_WALLET_PATH_PREFIX = '/wallet'
export const USER_PATH_PREFIX = '/u'
export const INTERN_PATH_PREFIX = '/intern'
export const EMAIL_PATH_PREFIX = '/email'
